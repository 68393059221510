import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'javascript/analytics/sentry';

import 'javascript/vendor/jquery'

import 'javascript/config/config';

import Turbolinks from 'turbolinks';
import Rails from '@rails/ujs';

import 'javascript/compatibility/polyfills';
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

import 'javascript/analytics/google_analytics_tracking';
import 'javascript/analytics/facebook_pixel';

import 'foundation-sites/dist/js/plugins/foundation.core';

import 'foundation-sites/dist/js/plugins/foundation.util.mediaQuery';
import 'foundation-sites/dist/js/plugins/foundation.util.keyboard'; // for accordion, reveal
import 'foundation-sites/dist/js/plugins/foundation.util.triggers'; // for reveal, toggler
import 'foundation-sites/dist/js/plugins/foundation.util.touch'; // for reveal
import 'foundation-sites/dist/js/plugins/foundation.util.motion';

import 'foundation-sites/dist/js/plugins/foundation.toggler';
import 'foundation-sites/dist/js/plugins/foundation.accordion';
import 'foundation-sites/dist/js/plugins/foundation.reveal';

import 'javascript/config/init-foundation';

import 'javascript/components/vendor_portal/vendor-event-sidebar';
import 'javascript/components/vendor_portal/vendor-selector';
import 'javascript/components/vendor_portal/product-menu-list';
import 'javascript/components/vendor_portal/product-list';
import 'javascript/components/vendor_portal/product-modal';
import 'javascript/components/vendor_portal/vendor-agreement';

Turbolinks.start();
Rails.start();

const application = Application.start();

const sharedContext = require.context("javascript/controllers/shared", true, /.js$/);
application.load(definitionsFromContext(sharedContext));
