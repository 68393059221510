import 'select2';

function formatItem(state) {
  return `
    <div class="select2-vendor-option">
      <div class="select2-vendor-option__icon">
        <svg width="24" height="24" version="1" xmlns="http://www.w3.org/2000/svg"><path d="M5 7a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0 4a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm4-4h10a1 1 0 0 1 0 2H9a1 1 0 1 1 0-2zm0 4h10a1 1 0 0 1 0 2H9a1 1 0 0 1 0-2zm-4 4a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm4 0h10a1 1 0 0 1 0 2H9a1 1 0 0 1 0-2z" fill-rule="evenodd"/></svg>
      </div>
      <div class="select2-vendor-option__content">
        <div class="select2-vendor-option__label">${state.text}</div>
        <div class="select2-vendor-option__type hide-for-small-only">Vendor</div>
      </div>
    </div>
  `;
}

$(document).on('turbolinks:load', () => {
  $('.js-vendor-selector').select2({
    minimumResultsForSearch: -1,
    dropdownCssClass: 'select2-vendor-dropdown',
    formatResult: state => formatItem(state),
    formatSelection: state => formatItem(state),
    escapeMarkup: m => m
  })
  .on('change', (event) => {
    Turbolinks.visit("/vendors/" + event.val);
  });
}).on('turbolinks:before-cache', () => {
  $('.js-vendor-selector').select2('destroy');
});
