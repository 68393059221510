import 'select2';

import { isToggledOn, getToggleClass } from './toggler-helpers';

function formatItem(state, isSelected) {
  return `
    <div class='select2-product-category-result ${isSelected ? 'is-selected' : ''}'>
      <span class='select2-product-category-name'>${state.text}</span>
    </div>
  `;
}

function fillInProductRow(productRow, data) {
  productRow.find('.js-product-display-name').text(data.name);
  productRow.find('.js-product-display-price').text(data.price);
  productRow.find('.js-product-display-category').text(data.category);
  const isAlcoholic = productRow.find('.js-product-display-alcoholic');
  if (data.is_alcoholic) {
    isAlcoholic.addClass('is-checked');
  } else {
    isAlcoholic.removeClass('is-checked');
  }
}

function resetForm(productRow) {
  const name = productRow.find('.js-product-display-name').text();
  const price = productRow.find('.js-product-display-price').text();
  const category = productRow.find('.js-product-display-category').text();
  const isAlcoholic = productRow.find('.js-product-display-alcoholic').hasClass('is-checked');

  productRow.find('.js-product-form-name').val(name);
  productRow.find('.js-product-form-price').val(price);
  productRow.find('.js-product-category-select').val(category).trigger('change');
  productRow.find('.js-product-form-alcoholic').prop('checked', isAlcoholic);
}

function recountProducts() {
  const numberOfProducts = $('.js-product-existing').length;
  $('.js-product-list-count').text(`${numberOfProducts}`);
}

function initializeProductCategorySelect(jqueryElement) {
  jqueryElement.select2({
    minimumResultsForSearch: -1,
    dropdownCssClass: 'select2-product-category-dropdown',
    formatResult: state => formatItem(state, false),
    formatSelection: state => formatItem(state, true),
    escapeMarkup: m => m
  });
}

function getSelectedProductIds() {
  const ids = [];
  $('.js-product-selector').each(function () {
    if (this.checked) {
      ids.push($(this).closest('.js-product').data('productId'));
    }
  });
  return ids;
}

function areAnyRowsChecked() {
  let anyChecked = false;
  $('.js-product-selector').each(function () {
    if (this.checked) {
      anyChecked = true;
      return true;
    }
  });
  return anyChecked;
}

function calculateSelection() {
  const drawer = $('.js-product-drawer');

  if (areAnyRowsChecked()) {
    $('.js-select-all-products').text('Deselect All');
    drawer.addClass(getToggleClass(drawer));
  } else {
    $('.js-select-all-products').text('Select All');
    drawer.removeClass(getToggleClass(drawer));
  }
}

function removeProductElement(productId) {
  const elementToRemove = $(`#product-${productId}-edit`);
  if (elementToRemove.hasClass('is-editing')) {
    overlay.removeClass(overlayTogglerClass);
  }
  elementToRemove.remove();
  calculateSelection();
  recountProducts();
}

function selectAllProducts() {
  $('.js-product-selector').each(function () {
    this.checked = true;
  });
  calculateSelection();
}

function deselectAllProducts() {
  $('.js-product-selector').each(function () {
    this.checked = false;
  });
  calculateSelection();
}

function deleteSelectedProducts(vendorId) {
  if (!confirm('Are you sure?')) return;

  const idsToDelete = getSelectedProductIds();

  Promise.all(idsToDelete.map(productId => {
    return $.ajax({
      url: `/vendors/${vendorId}/products/${productId}`,
      method: 'DELETE'
    }).then(() => {
      removeProductElement(productId)
    }, (xhr, __, statusText) => {
      if (xhr.status === 404) {
        removeProductElement(productId);
      }
      else if (xhr.status === 422) {
        $('#js-delete-warning').removeClass('hide');
      } else {
        alert(statusText);
      }
    });
  })).then(errors => {
    calculateSelection();
  });
}

$(document).on('turbolinks:load', () => {
  initializeProductCategorySelect($('.js-product-category-select'));

  const productList = $('.js-product-list');
  const vendorId = productList.data('vendorId');
  const canMakeNegativePrice = productList.data('canMakeNegativePrice');
  const overlay = $('.js-product-overlay');
  const overlayTogglerClass = getToggleClass(overlay);

  productList.on('change', '.js-product-selector', function () {
    calculateSelection();
  });

  productList.on('focus', 'input', function () {
    $(this).parent().addClass("is-focused");
  }).on('blur', 'input', function () {
    $(this).parent().removeClass("is-focused");
  });

  $('.js-select-all-products').click(function () {
    if (areAnyRowsChecked()) {
      deselectAllProducts();
    } else {
      selectAllProducts();
    }
  });

  $('.js-product-delete-all').click(function () {
    deleteSelectedProducts(vendorId);
  });

  productList.on('on.zf.toggler', '.js-product', function () {
    const thisProductEl = this;

    $('#product-new_vendor_product_price').val((1).toFixed(2));
    // turn off edit on other products when editing a product
    productList.find('.js-product').each(function () {
      if (this !== thisProductEl && isToggledOn($(this))) {
        $(this).foundation('toggle');
      }
    });
    overlay.addClass(overlayTogglerClass);
  }).on('off.zf.toggler', '.js-product', function () {
    resetForm($(this));
  });

  productList.on('ajax:success', '.js-product-delete', function () {
    const productId = $(this).data('productId');
    removeProductElement(productId);
  }).on('ajax:error', '.js-product-delete', function ({ detail: [, error, xhr] }) {
    if (xhr.status === 404) {
      const productId = $(this).data('productId');
      removeProductElement(productId);
    } else {
      alert(`Something went wrong: ${error}`);
    }
  });

  productList.on('ajax:success', '.js-product-edit-form', function ({ detail: [response] }) {
    const productRow = $(`#product-${response.id}-edit`);

    fillInProductRow(productRow, response);
    productRow.removeClass('is-editing');

    overlay.removeClass(overlayTogglerClass);
  }).on('ajax:error', '.js-product-edit-form', function ({ detail: [, error] }) {
    alert(`Something went wrong: ${error}`);
  }).on('ajax:before', '.js-product-edit-form', function () {
    return validatePriceCents($(`#product-${$(this).data('productId')}_vendor_product_price`).val());
  });

  productList.on('ajax:success', '.js-product-new-form', function ({ detail: [response] }) {
    const newProductRow = $('#product-new-edit');
    const newRow = $(response.body.childNodes[0]);

    newRow.insertAfter(newProductRow);
    newRow.foundation();
    initializeProductCategorySelect(newRow.find('.js-product-category-select'));

    newProductRow.removeClass('is-editing');
    resetForm(newProductRow);
    recountProducts();

    overlay.removeClass(overlayTogglerClass);
  }).on('ajax:error', '.js-product-new-form', function ({ detail: [, error] }) {
    alert(`Something went wrong: ${error}`);
  })
    .on('ajax:before', '.js-product-new-form', function () {
      return validatePriceCents($('#product-new_vendor_product_price').val());
    });

  function validatePriceCents(value) {
    if (!canMakeNegativePrice) {
      if (value <= 0.00) {
        alert("Product price must be greater than R0.00");
        return false;
      }
    }
  }
}).on('turbolinks:before-cache', () => {
  $('.js-product-category-select').select2('destroy');
});