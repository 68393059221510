import { isToggledOn, getToggleClass, toggleOn, toggleOff } from './toggler-helpers';
import Sortable from 'sortablejs';

const ENTER_KEY = 13;

function resetForm(productRow) {
  const price = productRow.find('.js-menu-product-display-price').text();
  productRow.find('.js-menu-product-form-price').val(price);
}

function getSelectedProductIds() {
  const ids = [];
  $('.js-modal-product-selector').each(function() {
    if (this.checked) {
      ids.push($(this).closest('.js-modal-product').data('productId'));
    }
  });
  return ids;
}

function calculateSelection() {
  const drawer = $('.js-modal-product-drawer');

  if (areAnyRowsChecked()) {
    toggleOn(drawer);
  } else {
    toggleOff(drawer);
  }
}

function getCurrentVendorProductIdsInMenu() {
  const productIds = [];
  $('.js-menu-product').each(function() {
    productIds.push($(this).data('vendorProductId'))
  });
  return productIds;
}

function areAnyRowsChecked() {
  let anyChecked = false;
  $('.js-modal-product-selector').each(function() {
    if (this.checked) {
      anyChecked = true;
      return true;
    }
  });
  return anyChecked;
}

$(document).on('turbolinks:load', () => {
  const modalProductList = $('.js-modal-product-list');
  const productMenuList = $('.js-product-menu-list');
  const vendorId = productMenuList.data('vendorId');
  const stationId = productMenuList.data('stationId');
  const productSelectionModal = $('#product-selection-modal');
  const overlay = $('.js-menu-product-overlay');

  $('.js-select-all-products-modal').click(function() {
    $('.js-modal-product-selector').each(function() {
      if (!$(this).prop('disabled')) {
        this.checked = true;
      }
    });
    calculateSelection();
  });

  productMenuList.on('focus', 'input', function() {
    $(this).parent().addClass("is-focused");
  }).on('blur', 'input', function(){
    $(this).parent().removeClass("is-focused");
  });

  productMenuList.on('on.zf.toggler', function({ target }) {
    // ignore events on the list items, since this event fires for them too
    if ($(target).hasClass('js-menu-product')) return;

    // turn off edit on products when entering edit list mode
    productMenuList.find('.js-menu-product').each(function() {
      if (isToggledOn($(this))) {
        $(this).foundation('toggle');
        toggleOff(overlay);
      }
    });
  });

  productMenuList.on('on.zf.toggler', '.js-menu-product', function() {
    const thisProductEl = this;
    // turn off edit on other products when editing a product
    productMenuList.find('.js-menu-product').each(function() {
      if (this !== thisProductEl && isToggledOn($(this))) {
        $(this).foundation('toggle');
      }
    });

    $(thisProductEl).find('.js-menu-product-form-price').focus().select();

    toggleOn(overlay);
  }).on('off.zf.toggler', '.js-menu-product', function() {
    resetForm($(this));
  });

  function updatePrice(productRow) {
    const stationProductId = productRow.data('stationProductId');
    const price = productRow.find('.js-menu-product-form-price').val();
    $.ajax({
      url: `/vendors/${vendorId}/station/${stationId}/menu_items/${stationProductId}`,
      method: 'PATCH',
      data: { station_product: { price } }
    }).then(data => {
      productRow.find('.js-menu-product-display-price').text(data.price);
      productRow.find('.js-menu-product-form-price').val(data.price);
      productRow.foundation('toggle');
      toggleOff(overlay);
    }, () => {
      alert('something went wrong :(');
    });
  }

  productMenuList.on('click', '.js-menu-product-form-save', function() {
    const productRow = $(this).closest('.js-menu-product');
    updatePrice(productRow);
  });

  productMenuList.on('keypress', '.js-menu-product-form-price', function(event) {
    if (event.which === ENTER_KEY) {
      event.preventDefault();
      const productRow = $(this).closest('.js-menu-product');
      updatePrice(productRow);
    }
  });

  modalProductList.on('change', '.js-modal-product-selector', function() {
    calculateSelection();
  });

  $('.js-modal-add-product-to-menu').on('click', function() {
    const productIdsToAdd = getSelectedProductIds();

    toggleOn($('.js-product-menu-editable'));

    $.ajax({
      url: `/vendors/${vendorId}/station/${stationId}/menu_item_rows`,
      method: 'GET',
      data: { product_ids: productIdsToAdd }
    }).then(data => {
      const newRows = $(data);
      productMenuList.append(newRows);
      newRows.foundation();
    }, () => {
      alert('something went wrong :(');
    });

    productSelectionModal.foundation('toggle');
  });

  productSelectionModal.on('on.zf.toggler', function() {
    const currentVendorProductIds = getCurrentVendorProductIdsInMenu();
    $('.js-modal-product-selector').each(function() {
      this.checked = false;
      const modalProduct = $(this).closest('.js-modal-product');
      const productId = modalProduct.data('productId');
      const isProductAlreadyInMenu = currentVendorProductIds.includes(productId);
      $(this).prop('disabled', isProductAlreadyInMenu);
      if (isProductAlreadyInMenu) {
        modalProduct.addClass('is-inactive');
      } else {
        modalProduct.removeClass('is-inactive');
      }
    });
    calculateSelection();
  });

  productMenuList.on('click', '.js-menu-product-remove', function() {
    $(this).closest('.js-menu-product').remove();
  });

  $('.js-product-menu-done').on('click', function() {
    const currentVendorProductIds = getCurrentVendorProductIdsInMenu();
    $.ajax({
      url: `/vendors/${vendorId}/station/${stationId}/menu_items`,
      method: 'POST',
      data: { product_ids: currentVendorProductIds }
    }).then(data => {
      productMenuList.html(data);
      productMenuList.find('li').foundation();
    }, () => {
      alert('something went wrong :(');
    });
  });
  if (productMenuList.length > 0) {
    const productMenuSortable = new Sortable(productMenuList.get(0), {
      handle: '.js-menu-product-handle',
      draggable: '.js-menu-product',
      animation: 150,

       // HTML5 DnD is terrible
       // https://www.quirksmode.org/blog/archives/2009/09/the_html5_drag.html
      forceFallback: true,

      onStart(evt) {
        $(document.body).addClass('drag-in-progress');
        productMenuList.addClass('is-sorting');
      },

      onEnd(evt) {
        $(document.body).removeClass('drag-in-progress');
        productMenuList.removeClass('is-sorting');
      }
    });
  }
});
