import { isToggledOn } from './toggler-helpers';

$(document).on('turbolinks:load', () => {
  const disableScrollClasses = 'disable-scroll-x-medium-down disable-scroll-y-small-only';
  const vendorEventsSidebar = $('#vendor-events-sidebar');
  if (vendorEventsSidebar.length > 0 && isToggledOn(vendorEventsSidebar)) {
    $('.js-page-overlay').addClass(disableScrollClasses);
  }
  vendorEventsSidebar.on('on.zf.toggler', () => {
    $('.js-page-overlay').addClass(disableScrollClasses);
  }).on('off.zf.toggler', () => {
    $('.js-page-overlay').removeClass(disableScrollClasses);
  });
});
