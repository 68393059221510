export function isToggledOn(jqueryElement) {
  const toggleClass = getToggleClass(jqueryElement);
  if (!toggleClass) return;
  return jqueryElement.hasClass(toggleClass);
}

export function getToggleClass(jqueryElement) {
  if (!jqueryElement) return null;
  if (!jqueryElement.data('toggler')) return null;
  return jqueryElement.data('toggler').replace(/^\./, '');
}

export function toggleOn(jqueryElement) {
  const toggleClass = getToggleClass(jqueryElement);
  if (!toggleClass) return;
  jqueryElement.addClass(toggleClass);
}

export function toggleOff(jqueryElement) {
  const toggleClass = getToggleClass(jqueryElement);
  if (!toggleClass) return;
  jqueryElement.removeClass(toggleClass);
}
