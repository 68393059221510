$(document).on('turbolinks:load', () => {
  const $modal = $('#vendor-agreement-modal');
  if ($modal.length <= 0) return;

  if (typeof $modal.data('accepted') === 'undefined') {
    $modal.foundation('open');
  }

  let currentPage = 0;
  const pageCount = $('.js-vendor-agreement-page').length;
  const lastPage = pageCount - 1;

  const previousButton = $('.js-vendor-agreement-previous');
  const checkbox = $('.js-vendor-agreement-checkbox');
  const checkboxInput = $('.js-vendor-agreement-checkbox-input');
  const pageContainer = $('.js-vendor-agreement-terms');
  const acceptButton = $('.js-vendor-agreement-submit');

  checkboxInput.on('change', function() {
    acceptButton.prop('disabled', !this.checked);
  });

  function resetForm() {
    checkboxInput.prop('checked', false);
    acceptButton.prop('disabled', true);
  }

  function goToPage(pageNumber) {
    if (pageNumber < 0 || pageNumber > lastPage) return;
    currentPage = pageNumber;

    $('.js-vendor-agreement-page').each(function(index) {
      if (index === currentPage) {
        $(this).removeClass('hide');
      } else {
        $(this).addClass('hide');
      }
    });

    const isOnFirstPage = (currentPage === 0);
    const isOnLastPage = (currentPage === lastPage);

    if (isOnFirstPage) {
      if(previousButton.is(':visible')){
        Foundation.Motion.animateOut(previousButton, 'fade-out', () => {});
      }
    } else if(!previousButton.is(':visible')) {
      Foundation.Motion.animateIn(previousButton, 'fade-in', () => {});
    }
    if (isOnLastPage) {
      resetForm();
      if(!checkbox.is(':visible')){
        Foundation.Motion.animateIn(checkbox, 'vendor-agreement-checkbox-appear', () => {});
      }
      $('.js-vendor-agreement-show-on-last-page').removeClass('hide');
      $('.js-vendor-agreement-hide-on-last-page').addClass('hide');
    } else {
      if(checkbox.is(':visible')) {
        Foundation.Motion.animateOut(checkbox, 'vendor-agreement-checkbox-dissappear', () => {});
      }
      $('.js-vendor-agreement-show-on-last-page').addClass('hide');
      $('.js-vendor-agreement-hide-on-last-page').removeClass('hide');
    }
  }

  $('.js-vendor-agreement-next').on('click', () => {
    pageContainer.scrollTop(0);
    goToPage(currentPage + 1);
  });

  $('.js-vendor-agreement-previous').on('click', () => {
    pageContainer.scrollTop(0);
    goToPage(currentPage - 1);
  });

  $modal.on('open.zf.reveal', () => {
    resetForm();
    goToPage(0);
  });

  $('.js-vendor-agreement-form').on('ajax:success', () => {
    $modal.foundation('close');
  });
}).on('turbolinks:before-cache', () => {
  const $modal = $('#vendor-agreement-modal');
  if ($modal.length <= 0) return;

  $modal.foundation('close');
});
